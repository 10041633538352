import PropTypes from "prop-types"
import React from "react"
import bp from "../styles/vars/bp"
import styled from "styled-components"
import Image from "../../components/image"
import colors from '../styles/vars/colors'
import {Row, Column } from "../styles/grid"
import {Subcopy} from "../styles/typography/typography"
import  {CaseBlock, CaseStudyTitle, CaseStudyHeader, CaseStudyBlock, CaseStudySubblock, 
        CaseStudyFromTo, CaseStudyArrow, FromToHeader } from "./caseStyle"

export const BlockWrapper = styled(CaseBlock)`
    .case__top{
        border-bottom: 1px ${colors.casestudyline} solid;
        h1{
            margin: 15px 0 2em 0;
        }
    }
    
    .case__image {
        width: 100%;
        img{
            object-fit: contain;
        }
    }

    .case__fromTo{
        .linkbreak{
            margin-bottom: 30px;
        }
    }

    .case__fromTo__contents{
        height: 100%;
        justify-content: space-between;
        p{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }

    .contact{
        position: absolute;
        left: 0;
        bottom: 65px;
    }

    @media (max-width: ${bp["bp-tablet"]}) {
        .case__top, .case__image, .case__fromTo {
            flex-direction: column;
        }
        
        .reverse{
            flex-direction: column-reverse;
        }
        .contact{
            bottom: 0px;
        }
    }
`

const Case3 = ({ content }) => {

    const listItems = (list) => list.map((item, idx) => {
        return ( <Subcopy key={'item'+ idx}> {item}</Subcopy> )
    });

  return ( 
    <BlockWrapper main padding>
        <Row spaceBtw alignTop pd={'50px 0 60px 0'} pd_s={'50px 0 0 0'} className="case__top">
            <Column half w_t={24} w_m={24}>
                <CaseStudyTitle>{content.blocks[0].header}</CaseStudyTitle>
                <CaseStudyHeader>{content.blocks[0].text}</CaseStudyHeader>
            </Column>
            <Column half w_t={24} w_m={24}>
            <CaseStudyBlock>
                <CaseStudySubblock>
                    <CaseStudyTitle>{content.blocks[1].header}</CaseStudyTitle>
                    <Subcopy>{content.blocks[1].text}</Subcopy>
                </CaseStudySubblock>
                <CaseStudySubblock>
                    <CaseStudyTitle>{content.blocks[2].header}</CaseStudyTitle>
                        {listItems(content.blocks[2].list)}
                    </CaseStudySubblock>
                </CaseStudyBlock>
            </Column>
        </Row>
            
        <Row full spaceBtw className="case__fromTo">
            <Column half w_t={24} w_m={24} pd={'35px 0 80px 0'} pd_m={'25px 0 0 0'}>
                <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_1_content[0]}</FromToHeader>
                <CaseStudyArrow />
                <FromToHeader className="linkbreak"><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[1]}</FromToHeader>
                <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[2]}</FromToHeader>
                <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[3]}</FromToHeader>
            </Column> 

            <Column half w_t={24} w_m={24} pd={'42px 0 50px 0'}>
                <Image className="case__image" img="case3" />
            </Column> 
        </Row>

        <Row full spaceBtw className="case__bottom" className="case__fromTo" pd_m={'0 0 50px 0'}>
            <Column half w_t={24} w_m={24}>
                <CaseStudyTitle><span>1/ </span>{content.fromto_1_header}</CaseStudyTitle>
                <Column className="case__fromTo__contents" pd={'35px 0 65px 0'}>
                    <Subcopy>{content.fromto_1_desc[0]}</Subcopy>
                    <Subcopy>{content.fromto_1_desc[1]}</Subcopy>
                    <Subcopy>{content.fromto_1_desc[2]}</Subcopy>
                    <Subcopy>{content.fromto_1_desc[3]}</Subcopy>
                </Column>
            </Column>
            <Column half w_t={24} w_m={24}>
                <CaseStudyTitle><span>2/ </span>{content.fromto_2_header}</CaseStudyTitle>
                <Column className="case__fromTo__contents" pd={'35px 0 65px 0'} >
                    <Subcopy>{content.fromto_2_desc}</Subcopy>
                    <Subcopy className="contact">{content.more}<a href="mailto:hello@cosignprojects.com">{content.more_link}</a></Subcopy>
                </Column>
            </Column>
        </Row>
    </BlockWrapper>
  )
}

Case3.propTypes = {
    content: PropTypes.object,
}

Case3.defaultProps = {
    content: {},
} 

export default Case3
