import styled, { css } from "styled-components"
import bp from "../styles/vars/bp"
import colors from "../styles/vars/colors"
import ArrowFromTo from "../../images/svg/arrow_fromTo.svg";
import {Divider, ContentContainer } from "../styles/grid"
import {Subcopy, BlockTitle, H2} from "../styles/typography/typography"


export const CaseBlock = styled(ContentContainer)`
    border-bottom: 0px;
    background: ${colors["yellowcase"]};
    flex-direction: column;
    p, a{
        color: ${colors["darkgrey"]};
     }
    a{
        text-decoration: underline;
        @media (hover: hover) {
            &:hover{
                color: ${colors["orange"]};
            }
        }
    }

`
export const CaseStudyTitle = styled(BlockTitle)`
    border-bottom: 1px ${colors["casestudyline"]} solid;
    border-top: 1px ${colors["casestudyline"]} solid;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    color: ${colors["darkgrey"]};
    
    span{
        margin-right: 15px;
    }
    @media (max-width: ${bp["bp-mobile"]}) {
        font-size: 17px;
        span{
            margin-right: 10px;
        }
    }
`

export const CaseStudyHeader = styled(H2)`
    margin: 15px 0;
    color: ${colors["darkgrey"]};
`

export const FromToHeader = styled(H2)`
    color: ${colors["orange"]};
    display: flex;
    align-items: baseline;
    margin: 15px 0;
    &:first-child{
        margin-top: 0;
    }
    p{
        color: ${colors["orange"]};
    }
`

export const CaseStudyBlock = styled(ContentContainer)`
    flex-direction: row; 
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: ${bp["bp-mobile"]}) {
        flex-direction: column;
        width: 100%;
    }
`


export const CaseStudySubblock = styled(ContentContainer)`
    flex-direction: column;
    width: 45%;
    p:nth-child(2){
        margin-top: 35px;
    }
    @media (max-width: ${bp["bp-mobile"]}) {
        width: 100%; 
        margin-bottom: 3em;
        flex-direction: column;
        p:nth-child(2){
            margin-top: 20px;
        }
    }
`

export const CaseStudyDivider = styled(Divider)`
    background: ${colors["greyline"]};
   ${props => props.mg &&
		css`
			height: calc(100% - ${props.mg} * 2);
			margin: ${props.mg} 0;
            @media (max-width: ${bp["bp-mobile"]}) {
                height: unset;
            }

		`
	}

`

export const CaseStudyFromTo = styled(Subcopy)`
    display: inline-block;
    margin-right: 20px;
`

export const CaseStudyArrow = styled.span`
    background-image: url(${ArrowFromTo});
    background-size: contain;
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 28px;
    display: inline-flex;

`