import React from "react"
import Case1 from "./case1.js";
import Case2 from "./case2.js";
import Case3 from "./case3.js";
import Case4 from "./case4.js";

const caseList = (content) => {
  return {
    'case1': <Case1 content={content}/>, 
    'case2': <Case2 content={content}/>,
    'case3': <Case3 content={content}/>,
    'case4': <Case4 content={content}/>
  }
}

const caseStudies = ({ id, content }) => { 
  return (
    <div className="casestudy">
      {caseList(content)['case'+id]}
    </div>
  )
}


export default caseStudies
