

import PropTypes from "prop-types"
import React from "react"
import bp from "../styles/vars/bp"
import styled from "styled-components"
import Image from "../../components/image"
import {Column, Row } from "../styles/grid"
import {Subcopy} from "../styles/typography/typography"
import  {CaseBlock, CaseStudyTitle, CaseStudyHeader,FromToHeader, CaseStudyBlock, CaseStudySubblock, 
        CaseStudyFromTo, CaseStudyArrow } from "./caseStyle"

export const BlockWrapper = styled(CaseBlock)`
        .case__top{
            h1{
                margin: 30px 0 50px 0;
            }
        }
        .case__image {
            width: 100%;
            img{
                object-fit: contain !important;
            }
        }

        .case__fromTo{
            align-items: normal;
            .case__fromTo__contents {
                p:nth-child(2){
                    margin-top: 15px;
                }
            }
        }
    
        .case__bottom{
            justify-content: flex-end;
            padding-bottom: 65px;
            a{
                text-decoration: underline;
            }
        }

        @media (max-width: ${bp["bp-tablet"]}) {
            .case__top, .case__image, .case__fromTo {
                flex-direction: column;
            }
            
            .reverse{
                flex-direction: column-reverse;
            }
        }
    `

const Case2 = ({ content }) => {

  const listItems = (list) => list.map((item, idx) => {
    return ( <Subcopy key={'item'+ idx}> {item}</Subcopy> )
});

  return ( 
    <BlockWrapper main padding>
        <Row spaceBtw alignTop pd={'50px 0 42px 0'} pd_m={'50px 0 0 0'} className="case__top">
            <Column half w_t={24} w_m={24}>
                <CaseStudyTitle>{content.blocks[0].header}</CaseStudyTitle>
                <CaseStudyHeader>{content.blocks[0].text}</CaseStudyHeader>
            </Column>
            <Column half w_t={24} w_m={24}>
                <CaseStudyBlock>
                    <CaseStudySubblock>
                        <CaseStudyTitle>{content.blocks[1].header}</CaseStudyTitle>
                        <Subcopy>{content.blocks[1].text}</Subcopy>
                    </CaseStudySubblock>
                    <CaseStudySubblock>
                    <CaseStudyTitle>{content.blocks[2].header}</CaseStudyTitle>
                        {listItems(content.blocks[2].list)}
                    </CaseStudySubblock>
                </CaseStudyBlock>
            </Column>
        </Row>

        <Column full>
            <Image className="case__image" img="case2" />
        </Column>

        <Column full pd={'30px 0 0 0'}>
            <CaseStudyTitle><span>1/ </span>{content.fromto_1_header}</CaseStudyTitle>
            <Row spaceBtw alignTop className="case__fromTo reverse" pd={'36px 0 55px 0'}  pd_m={'25px 0 55px 0'}>
                <Column half className="case__fromTo__contents" pd_m={'35px 0 0 0'}>
                    <Subcopy>{content.fromto_1_desc[0]}</Subcopy>
                    <Subcopy>{content.fromto_1_desc[1]}</Subcopy>
                </Column>
                <Column half> 
                    <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_1_content[0]}</FromToHeader>
                    <CaseStudyArrow />
                    <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[1]}</FromToHeader>
                </Column>
            </Row>
        </Column>
    
        <Column full>
            <CaseStudyTitle><span>2/ </span>{content.fromto_2_header}</CaseStudyTitle>
            <Row alignTop spaceBtw className="case__fromTo" pd={'36px 0 65px 0'}  pd_m={'25px 0 65px 0'}>
                <Column half> 
                     <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_2_content[0]}</FromToHeader>
                    <CaseStudyArrow />
                     <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_2_content[1]}</FromToHeader>
                </Column>
                <Column half spaceBtw className="case__fromTo__contents" pd_m={'35px 0 0 0'}> 
                    <Subcopy>{content.fromto_2_desc}</Subcopy>
                    <Subcopy>{content.more}<a href="mailto:hello@cosignprojects.com">{content.more_link}</a></Subcopy>
                </Column>
            </Row>
        </Column>

    </BlockWrapper>
  )
}

Case2.propTypes = {
    content: PropTypes.object,
}

Case2.defaultProps = {
    content: {},
} 

export default Case2
