

import PropTypes from "prop-types"
import React from "react"
import bp from "../styles/vars/bp"
import styled from "styled-components"
import Image from "../../components/image"
import colors from '../styles/vars/colors'
import {Row, Column } from "../styles/grid"
import {Subcopy,  QuoteIcon, Quote} from "../styles/typography/typography"
import  {CaseBlock, CaseStudyTitle, CaseStudyHeader, CaseStudyBlock, CaseStudySubblock, 
    CaseStudyFromTo, CaseStudyArrow, FromToHeader } from "./caseStyle"

export const BlockWrapper = styled(CaseBlock)`
    .case__top{
        border-bottom: 1px ${colors.casestudyline} solid;
        h1{
            margin: 30px 0 50px 0;
        }
    }
    .case__image {
        width: 100%;
        margin-bottom: 50px;
        img{
            object-fit: contain;
        }
    }

    .case__quote{
        color: ${colors["orange"]};
        width: 65%;
    }
   
    .case__fromTo{
        .linebreak{
            margin-bottom: 90px;
        }

    }

    @media (max-width: ${bp["bp-tablet"]}) {
        .case__top, .case__image, .case__fromTo {
            flex-direction: column;
        }
        
        .reverse{
            flex-direction: column-reverse;
        }
        .case__quote{
            width: 90%;
        }
        .case__image{
            margin: 50px 0 50px 0;
        }
    }
`

const Case1 = ({ content }) => {

    const listItems = (list) => list.map((item, idx) => {
        return ( <Subcopy key={'item'+ idx}> {item}</Subcopy> )
    });

  return ( 
    <BlockWrapper main padding>
         <Row spaceBtw alignTop pd={'50px 0 0 0'} className="case__top reverse">
            <Column half w_t={24} w_m={24}>
                <Image className="case__image" img="case4" />
            </Column>
            <Column half w_t={24} w_m={24}>
                <CaseStudyTitle>{content.blocks[0].header}</CaseStudyTitle>
                <CaseStudyHeader>{content.blocks[0].text}</CaseStudyHeader>
                <CaseStudyBlock>
                    <CaseStudySubblock>
                        <CaseStudyTitle>{content.blocks[1].header}</CaseStudyTitle>
                        <Subcopy>{content.blocks[1].text}</Subcopy>
                    </CaseStudySubblock>
                    <CaseStudySubblock>
                        <CaseStudyTitle>{content.blocks[2].header}</CaseStudyTitle>
                        {listItems(content.blocks[2].list)}
                    </CaseStudySubblock>
                </CaseStudyBlock>
            </Column>
        </Row>

        <Column full pd={'86px 0 70px 0'} center>
            <QuoteIcon>“</QuoteIcon>
            <Quote className="case__quote" dangerouslySetInnerHTML={{__html: content.quote}}></Quote>
        </Column>

        <Column full>
            <CaseStudyTitle><span>1/ </span>{content.fromto_1_header}</CaseStudyTitle>

            <Row alignTop spaceBtw className="case__fromTo reverse" pd={'36px 0 0 0'} pd_m={'25px 0 40px 0'}>
                <Column half pd_m={'35px 0 0 0'}>
                    <Subcopy>{content.fromto_1_desc[0]}</Subcopy>
                </Column>
                <Column half> 
                    <Column> 
                        <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_1_content[0]}</FromToHeader>
                        <CaseStudyArrow />
                        <FromToHeader className="linebreak"><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[1]}</FromToHeader>
                    </Column>
                    <Column> 
                        <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_2_content[0]}</FromToHeader>
                        <CaseStudyArrow />
                        <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_2_content[1]}</FromToHeader>
                    </Column>
                </Column>
            </Row>

            <Row full pd={'0 0 65px 0'}>
                <Subcopy>{content.more}<a href="mailto:hello@cosignprojects.com">{content.more_link}</a></Subcopy>
            </Row>
        </Column>
    </BlockWrapper>
  )
}

Case1.propTypes = {
    content: PropTypes.object,
}

Case1.defaultProps = {
    content: {},
} 

export default Case1
