

import PropTypes from "prop-types"
import { gsap } from "gsap";
import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components"
import { Container, TitleContainer, Row, Column } from "../styles/grid"
import { BlockTitle, Subcopy, H1, H2 } from "../styles/typography/typography"
import colors from "../styles/vars/colors"
import bp from "../styles/vars/bp"
import ArrowIcon from "../../images/svg/arrow_down.svg";
import ArrowIconHover from "../../images/svg/arrow_down_hover.svg";
import CaseStudy from "../caseStudies/caseStudy"
import classNames from 'classnames'
import animateScrollTo from 'animated-scroll-to';

export const BlockWrapper = styled(Container)`
    .home__solve__header{
        margin: 35px 0px 155px 0px;
    }
    .home__solve__list {
        margin-bottom: 110px;
        border-top: 1px ${colors.lightgreyline} solid;
    }
    .list__header {
        height: 110px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px ${colors.lightgreyline} solid;
        transition: all 0.2s ease-out;
        z-index: 9;
       
        @media (hover: hover) {
          cursor: pointer;
          &:hover{
            .list__header__id, .list__header__text, .list__header__cta {
              color: ${colors["orange"]};
              .arrow__icon{
                background-image: url(${ArrowIconHover});
              }
            }
          }
          p,h1,span{
            cursor: pointer;
          }
        }

        .list__header__container{
          align-items: flex-start;
          align-self: center;
        }

        .list__header__id{
            align-self: flex-start;
        }

        .list__header__text{
          margin: 0 0 0 30px;
          display: inline-flex;
          align-self: flex-start;
          justify-content: center;
          align-items: center;
          
          @media (min-width: ${bp["bp-tablet"]}) {
            height: 40px;
          }

          @media only screen and (min-width: 415px) and (max-width: ${bp["bp-tablet"]})  {
            font-size: 30px;
          }
        }

        .list__header__cta {
          align-self: center;
          display: flex;
          align-items: center;
          width: 150px;
          justify-content: flex-end;
          .list__header__cta__label {
            font-size: 16px;
          }
          p{
                margin:0;
            }

          .arrow__icon{
            background-image: url(${ArrowIcon});
            background-size: contain;
            background-repeat: no-repeat;
            background-size: contain;
            width: 17px;
            height: 11px;
            margin-left: 10px;
            display: inline-flex;
            &.-close{
                transform: rotateZ(0deg);
            };
            &.-open{
                transform: rotateZ(180deg);
               
            };
          
          }
        }
    }

    @media (max-width: ${bp["bp-tablet"]}) {
      .list__header {
        width: calc(100% - 100px);
        justify-content: space-between;
      .list__header__container{
          align-self: center;
        }
      }
    }

    @media (max-width: ${bp["bp-mobile"]}) {
        .home__solve__header{
          margin: 20px 0 1em 0;    
        }
        .list__header{
          width: calc(100% - 60px);
          height: 150px;
          flex-direction: column;
          justify-content: center;

           .list__header__text{
            transition: all 0.3s ease;
            margin: 0 0 0 18px;
           }
           
            .list__header__cta {
              align-self: flex-end;
              justify-content: flex-end;
              position: absolute;
              bottom: 45px;

              .list__header__cta__label{
                display: none;
              }
            }
        }
      
        .home__solve__list {
            margin-bottom: 50px;
        }

        .stick { 
          &.--open {
          .list__header {
            height: 110px;
            &.list__2, &.list__3{
              height: 140px;
            }
            .list__header__text{
                  line-height: 30px;
                  font-size: 22px;
                  margin-left: 10px;
                  width: 85%;
                  transition: all 0.3s ease;
              }
            }
          }
        }
      }

      @media (max-width: ${bp["bp-mobile-s"]}) {
      .list__header{
          height: 200px;
          &.list__3{
            height: 250px;
          }
        }
    }
 `
export const CaseWrapper = styled(Container)`
    height: 0;
    overflow-y: hidden;
    &.case--open{
      border-bottom: 1px ${colors.lightgreyline} solid;
    }

    &.loaded{
      display: none;
    }

`

const Block3 = ({ content, navID, offset }) => {
  const [caseStudy, setCaseStudy] = useState(-1);
  const blockRef = useRef();
  const refs = useRef({});
  const caseRefs = useRef({});
  const topRefs = useRef({});
  const caseBlockRefs = useRef({});
  const [stick, setStick] = useState();
  const [scrollY, setScrollY] = useState(9999);
  const [heights, setHeights] = useState([]);
  const [tops, setTops] = useState([]);

  const listClasses = classNames('home__solve__list__item', {
    stick: stick,
  })

  useEffect(() => {
    updateAll();
    window.addEventListener("resize", updateAll);
    return () => window.removeEventListener("resize", updateAll);
  },[])

  useEffect(() => {
    navID === 3 && animateScrollTo(blockRef.current, {verticalOffset: offset,  maxDuration: 600});
}, [navID])

  useEffect(() => {
    const handleScroll = () => {
      if (caseStudy !== -1) {
        if (window.pageYOffset > scrollY && window.pageYOffset < scrollY + heights[caseStudy]){   
          stickHeader(true);
        } else if (window.pageYOffset >= (scrollY + heights[caseStudy])) {
          openCaseStudy(caseStudy, true);
          stickHeader(false);
        } else {
          stickHeader(false);
        }
      } 
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [caseStudy])



  const updateAll = () =>{
    let _t = [];
    let _t_1 = topRefs.current[0].offsetTop;
    let _t_h = window.innerWidth > 768 ? 111 : window.innerWidth > 420 ? 150 : 200;
    for (const idx in caseRefs.current) {
      _t.push(_t_1 + _t_h * idx - 50);
    }
    setTops(_t);
    updateHeight();
  }

  const stickHeader = (stick) =>{
    setStick(stick);
    refs.current[caseStudy] && gsap.set(refs.current[caseStudy], {
      position: stick ? 'sticky' : 'relative',
      top: stick ? '45px' : 'unset'
    });
  }

  const updateHeight = () =>{
    let _h = [];
    for (const idx in caseRefs.current) {
      _h.push(caseBlockRefs.current[idx].getBoundingClientRect().height);
    }
    setHeights(_h);
  }


  const openCaseStudy = (idx, autoClose) => {
    updateHeight();

    if(caseStudy === -1) {
      setScrollY(tops[idx]);
      gsap.to(caseRefs.current[idx], {
        height: heights[idx],
        opacity: 1,
        ease: "power1.in",
        duration: 0.5, 
      });
      setCaseStudy(idx);
    } 

    if (caseStudy !== -1 && caseStudy === idx ) {
      setScrollY(9999);
      if(autoClose){
       // animateScrollTo(0, tops[idx])
        window.scrollTo(0, tops[idx]);
      } else {
        stick && window.scrollTo(0, tops[idx]);
      }
     
      gsap.to(caseRefs.current[idx], {
        height: 0,
        opacity: 0,
        ease: "power1.out",
        duration: autoClose ? 0 : 0.5, 
      });

       setCaseStudy(-1);
    }


    if (caseStudy !== -1 && caseStudy !== idx) {
        setScrollY(tops[idx]);
        stick && window.scrollTo(0, tops[idx]);
        gsap.to(caseRefs.current[caseStudy], {
          height: 0,
          opacity: 0,
          duration: 0.5, 
          ease: "power1.out",
          onComplete: ()=>{
            gsap.to(caseRefs.current[idx], {
              height: heights[idx],
              opacity: 1,
              ease: "power1.in",
              duration: 0.5, 
            });
          }
        });


      setCaseStudy(idx);
    }
  }


  const cases = content.casestudies.map((item, idx) => 
    <div key={`case${idx}`} className={`${listClasses} ${caseStudy === idx ? '--open' : '--close'}`} ref={(el) => topRefs.current[idx] = el}>
      <Row padding className={`list__header list__${idx}`} bgColor={colors.lightgrey} ref={(el) => refs.current[idx] = el} onClick={() => openCaseStudy(idx, false)}>
        <Row className="list__header__container">
          <Subcopy className="list__header__id">{item.id}</Subcopy>
          <H2 className="list__header__text">{item.title}</H2>
        </Row>
        <Subcopy className="list__header__cta"><span className="list__header__cta__label">{content.cta}</span>
          <span className={`arrow__icon ${caseStudy === idx ? '-open' : '-close'}`} aria-controls='case__panel'></span>
        </Subcopy>
      </Row>
      <CaseWrapper ref={(el) => caseRefs.current[idx] = el} className={caseStudy === idx && 'case--open'}>
        <Row bgColor={colors.background1} className={'case__block'} ref={(el) => caseBlockRefs.current[idx] = el} >
          <CaseStudy id={idx + 1} open={caseStudy === idx} content={item.extend} />
        </Row>
      </CaseWrapper>
    </div>
  );


  return (
    <BlockWrapper main bgColor={colors.lightgrey} ref={blockRef}>
      <TitleContainer padding><BlockTitle>{content.title}</BlockTitle></TitleContainer>
      <Column padding> <H1 className="home__solve__header">{content.header}</H1> </Column>
      <div className='home__solve__list'>{cases}</div>
    </BlockWrapper>
  )
}

Block3.propTypes = {
  content: PropTypes.object,
}

Block3.defaultProps = {
  content: {},
}

export default Block3
