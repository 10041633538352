

import PropTypes from "prop-types"
import React from "react"
import bp from "../styles/vars/bp"
import colors from "../styles/vars/colors"
import styled from "styled-components"
import Image from "../../components/image"
import {Column, Row } from "../styles/grid"
import {Subcopy,  QuoteIcon, Quote} from "../styles/typography/typography"
import  {CaseBlock, CaseStudyTitle, CaseStudyHeader, CaseStudyBlock, CaseStudySubblock, 
    CaseStudyFromTo, CaseStudyArrow, FromToHeader } from "./caseStyle"

export const BlockWrapper = styled(CaseBlock)`
    .case__top{
        border-bottom: 1px ${colors.casestudyline} solid;
        h1{
            margin: 0 0 60px 0;
        }
    }

    .case__image {
        width: 100%;
        img{
            object-fit: contain;
        }
    }
    .case__fromTo__bottom__right{
        p:last-child{
            margin-top: 50px;
        }
    }

    .case__quote{
        width: 70%;
        margin: auto;
        span{
            color: ${colors["orange"]};
            margin-bottom: 20px;
        }
        .case__quote__text{
            color: ${colors["orange"]};
            margin-bottom: 0;
        }
    }

    .case__fromTo{
        .case__fromTo__contents {
            p:nth-child(2){
                margin-top: 15px;
            }
        }
        .case__fromTo__title{
            p, h1{
                color: ${colors["orange"]};
            }
        }
    }

    .case__bottom{
        justify-content: flex-end;
        margin-bottom: 50px;
        a{
            text-decoration: underline;
        }
    }

    @media (max-width: ${bp["bp-tablet"]}) {
        .case__top, .case__image, .case__fromTo {
            flex-direction: column;
        }
        
        .reverse{
            flex-direction: column-reverse;
        }
        .case__image {
            margin: 50px 0 50px 0;
        }
        .case__quote{
            width: 90%;
        }
    }

    @media (max-width: ${bp["bp-mobile"]}) {
        .case__image {
            margin: 0px 0 50px 0;
        }
    }
`

const Case1 = ({ content }) => {

    const listItems = (list) => list.map((item, idx) => {
        return ( <Subcopy key={'item'+ idx}> {item}</Subcopy> )
    });

  return ( 
    <BlockWrapper main padding>
        <Row full pd={'50px 0 42px 0'} pd_m={'50px 0 30px 0'}>
             <CaseStudyTitle>{content.blocks[0].header}</CaseStudyTitle>
        </Row>
        <Row spaceBtw alignTop pd={'0px 0px 55px 0px'} pd_m={'0px'} className="case__top">
            <Column half mg_t={'-15px'}>
                <CaseStudyHeader>{content.blocks[0].text}</CaseStudyHeader>
                <CaseStudyBlock>
                    <CaseStudySubblock>
                        <CaseStudyTitle>{content.blocks[1].header}</CaseStudyTitle>
                        <Subcopy>{content.blocks[1].text}</Subcopy>
                    </CaseStudySubblock>
                    <CaseStudySubblock>
                        <CaseStudyTitle>{content.blocks[2].header}</CaseStudyTitle>
                        {listItems(content.blocks[2].list)}
                    </CaseStudySubblock>
                </CaseStudyBlock>
            </Column>

            <Column half w_t={24} w_m={24}>
                <Image className="case__image" img="case1" />
            </Column>
        </Row>

        <Column className="case__quote" full pd={'90px 0 95px 0'} center>
            <QuoteIcon>“</QuoteIcon>
            <Quote  className="case__quote__text" dangerouslySetInnerHTML={{__html: content.quote}}></Quote>
        </Column>

        <Column full>
            <CaseStudyTitle><span>1/ </span>{content.fromto_1_header}</CaseStudyTitle>
            <Row spaceBtw alignTop className="case__fromTo reverse">
                <Column half className="case__fromTo__contents" pd={'38px 0 60px 0'}>
                    <Subcopy>{content.fromto_1_desc[0]}</Subcopy>
                    <Subcopy>{content.fromto_1_desc[1]}</Subcopy>
                </Column>
             
                <Column half className="case__fromTo__title"  pd={'36px 0 0 0'} pd_m={'25px 0 0 0'}> 
                    <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_1_content[0]}</FromToHeader>
                    <CaseStudyArrow />
                    <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_1_content[1]}</FromToHeader>
                </Column>
            </Row>
        </Column>

        <Column full>
            <CaseStudyTitle><span>2/ </span>{content.fromto_2_header}</CaseStudyTitle>
            <Row spaceBtw alignTop className="case__fromTo">
                <Column half className="case__fromTo__title" pd={'36px 0 0 0'} pd_m={'25px 0 0 0'}> 
                    <FromToHeader><CaseStudyFromTo>from/</CaseStudyFromTo>{content.fromto_2_content[0]}</FromToHeader>
                    <CaseStudyArrow />
                    <FromToHeader><CaseStudyFromTo>to/</CaseStudyFromTo>{content.fromto_2_content[1]}</FromToHeader>
                </Column>
               
                <Column half className="case__fromTo__bottom__right" pd={'38px 0 65px 0'} pd_m={'25px 0 65px 0'}> 
                    <Subcopy>{content.fromto_2_desc} </Subcopy>
                    <Subcopy>{content.more}<a href="mailto:hello@cosignprojects.com">{content.more_link}</a></Subcopy>
                </Column>
            </Row>
        </Column>
    </BlockWrapper>
  )
}

Case1.propTypes = {
    content: PropTypes.object,
}

Case1.defaultProps = {
    content: {},
} 

export default Case1
