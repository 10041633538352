
import PropTypes from "prop-types"
import React, { useEffect, useRef } from 'react';
import styled from "styled-components"
import Image from "../../components/image"
import {Container, Row, Column, TitleContainer, Divider} from "../styles/grid"
import {BlockTitle, Subcopy, H2 } from "../styles/typography/typography"
import colors from "../styles/vars/colors"
import bp from "../styles/vars/bp"
import animateScrollTo from 'animated-scroll-to';

export const BlockWrapper = styled(Container)`
    .home__are__half{
        margin: 40px 0 90px 0;
        &.left{
            p{
                margin-bottom: 15px;
            }
            span{
                display: inline-block;
            }
            a{
                color: ${colors["darkgrey"]};
                border-bottom: 1px #403937 solid;
                @media (hover: hover) {
                    &:hover{
                        color: ${colors["orange"]};
                        border-bottom: 1px ${colors["orange"]} solid;
                    }
                }
            }
        }
       
        &.right{
            h1:last-child{
                 margin-top: 20px;
            }
            a{
                color: ${colors["darkgrey"]};
                border-bottom: 1px #403937 solid;
                height: calc( (20px + (42 - 30) * ((100vw - 414px) / (1024 - 414))));
                display: inline-flex;

                @media (max-width: ${bp["bp-mobile"]}) {
                    height: 38px;
                }
                @media (hover: hover) {
                &:hover{
                    color: ${colors["orange"]};
                    border-bottom: 1px ${colors["orange"]} solid;
                }
            }
            }
           
            
        }
    }

    @media (min-width: ${bp["bp-tablet"]}) {
        .home__are__half{
            &:first-child{
                p{
                    width: 80%;
                }
            }

            &.right{
                height: calc(100% - 200px);
                h1:first-child{
                    margin-bottom: 0;
                }
                h1:last-child{
                    width: 95%;
                    margin-bottom: 0;
                }
            }
        }
        .home__are__image{
            width: 90%;
        }
    }

    @media (max-width: ${bp["bp-tablet"]}) {
        .home__are {
            flex-direction: column;
        }
        .home__are__image{
            width: 100%;
            margin-top: 75px;
        }
        .home__are__half{
            margin: 35px 0 50px 0;
        }
        .right__container{
            border-top: 2px ${colors.darkgreyline} solid;
        }
        .left{
            h1{
                width: 80%;
            }
        }

    }
 `

const Block4 = ({ content, navID, offset }) => {
    const ref = useRef();
    const ref2 = useRef();

    useEffect(() => {
        navID === 4 && animateScrollTo(ref.current, {verticalOffset: offset, maxDuration: 600});
        navID === 5 && animateScrollTo(ref2.current, {verticalOffset: offset, maxDuration: 600});
    }, [navID])

  return ( 
    <BlockWrapper main ref={ref}>
         <Row full spaceBtw className="home__are">
            <Column w={12} w_t={24} w_m={24} bgColor={colors.lightpink}>
                <TitleContainer padding><BlockTitle>{content.title[0]}</BlockTitle></TitleContainer>
                <Column padding className="home__are__half left">
                    <H2 className="linebreak" dangerouslySetInnerHTML={{__html:content.description[0]}}></H2>
                    <Subcopy dangerouslySetInnerHTML={{__html: content.textBlock[0]}}></Subcopy>
                    <Subcopy dangerouslySetInnerHTML={{__html: content.textBlock[1]}}></Subcopy>
                    <Image className ="home__are__image" img="whoweare" />
                </Column>
            </Column>
            <Divider desktop></Divider>
            <Column w={12} w_t={24} w_m={24} bgColor={colors.background1} className="right__container" ref={ref2}>
                <TitleContainer padding><BlockTitle>{content.title[1]}</BlockTitle></TitleContainer>
                <Column spaceBtw padding className="home__are__half right">
                   <H2>{content.description[1]}</H2>
                   <H2><a href="mailto:hello@cosignprojects.com">{content.contact[0]}</a>{content.contact[1]}</H2>
                </Column>
            </Column>
        </Row>
    </BlockWrapper>
  )
}

Block4.propTypes = {
    content: PropTypes.object,
}

Block4.defaultProps = {
    content: {},
} 

export default Block4
