

import PropTypes from "prop-types"
import React, { useEffect, useRef } from 'react';
import styled from "styled-components"
import {Container, Row, Column, TitleContainer, Divider} from "../styles/grid"
import {BlockTitle, Subcopy, H2, QuoteIcon, Quote, QuoteAuthor} from "../styles/typography/typography"
import colors from "../styles/vars/colors"
import bp from "../styles/vars/bp"
import animateScrollTo from 'animated-scroll-to';

export const BlockWrapper = styled(Container)`
    .home__create__list {
        list-style: none;
        margin-bottom: 110px;

        li{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .home__create__right {
        @media (min-width: ${bp["bp-desktop-l"]}) {
            min-height: 1000px;
        }
     }
    .home__create__left{
        margin: 40px 0 100px 0;
    }
    .home__create__right__quote {
        height: calc(100% - 80px);
    }
    .home__create__quote {
        margin-bottom: 50px;
        width: calc( 100% - 130px * 2 );
        &:last-child{
            margin-bottom: 0px;
        }  
        @media (max-width: ${bp["bp-desktop"]}) {
            width: calc( 100% - 60px * 2 );
        }
        @media (max-width: ${bp["bp-mobile"]}) {
            width: calc( 100% - 30px * 2 );
        }
    }
    .quote_0{
        @media (min-width: ${bp["bp-mobile"]}) {
            width: 70%;
        }
    }

    @media (max-width: ${bp["bp-tablet"]}) {
       .home__create__content{
            flex-direction: column;
            padding: 0;
            justify-content: space-between;
        }

        .home__create__left {
            padding: 0 30px;
            margin: 35px 0 60px 0;
        }
        .home__create__right {
            border-top: 2px ${colors.darkgreyline} solid;
        }

        .home__create__list {
            margin-bottom: 80px;
        }
       
        .home__create__quote {
            margin-bottom: 40px;
            max-width: unset;
            padding: 0 30px;
        }

        .home__create__right__quote{
             margin: 80px 0 105px 0;
        }
       
    }
 `


const Block2 = ({ content, navID, offset }) => {
    const ref = useRef();
    const ref2 = useRef();

    useEffect(() => {
        navID === 1 && animateScrollTo(ref.current, {verticalOffset: offset, maxDuration: 600});
        navID === 2 && animateScrollTo(ref2.current, {verticalOffset: offset, maxDuration: 600});
    }, [navID])

    const services = content.list.map((item, idx) =>
        <li key={`list${idx}`}>{item}</li>
    );

    const quotes = content.quotes.map((item, idx) =>
        <div className={`home__create__quote quote_${idx}`} key={`quote${idx}`}>
            <Quote dangerouslySetInnerHTML={{__html: item.richText}}></Quote>
            <QuoteAuthor>{item.author}</QuoteAuthor>
        </div>
    );


  return ( 
    <BlockWrapper main>
         <Row spaceBtw className="home__create__content">
            <Column w={12} bgColor={colors.background2} ref={ref}>
                <TitleContainer padding><BlockTitle>{content.title[0]}</BlockTitle></TitleContainer>
                <Column padding className="home__create__left">
                    <H2 className="home__create__list">{services}</H2>
                    <Subcopy>{content.text}</Subcopy>
                </Column>
            </Column>
            <Divider desktop></Divider>
            <Column w={12} bgColor={colors.lightpink} className="home__create__right" ref={ref2}>
                <TitleContainer padding><BlockTitle>{content.title[1]}</BlockTitle></TitleContainer>
                <Column center flex_dir={'column'} className="home__create__right__quote" >
                    <QuoteIcon>“</QuoteIcon>
                    {quotes}
                </Column>
            </Column>
        </Row>
    </BlockWrapper>
  )
}

Block2.propTypes = {
    content: PropTypes.object,
}

Block2.defaultProps = {
    content: {},
} 

export default Block2
