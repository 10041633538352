
import PropTypes from "prop-types"
import React, { useEffect, useRef } from 'react';
import styled from "styled-components"
import {ContentContainer} from "../styles/grid"
import {H1} from "../styles/typography/typography"
import colors from '../styles/vars/colors'
import Image from "../../components/image"
import bp from "../styles/vars/bp"
import animateScrollTo from 'animated-scroll-to';

const HeroContainer = styled(ContentContainer)`
    .hero{
      margin: 40px auto 60px auto;
        .hero__title{
          width: 65%;
          align-self: flex-end;
          z-index: 1;
        }

      .hero__image{
        width: 35%;
        border: 1px solid darkgrey;
        height: 100%;
        object-fit: cover;
      }

      @media (min-width: ${bp["bp-mobile"]}) {
        .hero__image{
          margin-right: 30px;
          min-width: 310px;
        }
        .hero__title{
          margin-bottom: -12px;
        }
      }
  } 

  @media (max-width: ${bp["bp-mobile"]}) {
      background: #E9CED5 !important;
      .hero{
        flex-direction: column-reverse;
        overflow: hidden;
        margin: 30px auto 0px auto;
        .hero__title{
          width: 100%;
          margin-bottom: 10px;
        }
        .hero__image{
          border: 0px;
          width: 117%;
          left: -9%;
        }
      }
    } 

   
`


const BlockHero = ({ header, navID, offset }) => { 
  const ref = useRef();
 
   useEffect(() => {
    navID === 0 && animateScrollTo(ref.current, {verticalOffset: offset, maxDuration: 600});
  }, [navID])

  return ( 
    <HeroContainer ref={ref} className="heroContainer" main padding bgColor = {colors.background1}>
      <ContentContainer className ="hero">
          <Image className ="hero__image" img="hero" />
          <H1 className="hero__title">{header}</H1>
       </ContentContainer>
    </HeroContainer>
  )
}

BlockHero.propTypes = {
  header: PropTypes.string,
}

BlockHero.defaultProps = {
  header: ``,
} 

export default BlockHero
