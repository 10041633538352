import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import BlockHero from "../components/blocks/blockHero"
import Block2 from "../components/blocks/block2.js"
import Block3 from "../components/blocks/block3.js"
import Block4 from "../components/blocks/block4.js"
import styled from "styled-components"

export const query = graphql`
  query {
    site {
      siteMetadata {
        main {
          homepage {
            blockHero {
              header
            },
            block2 {
              title,
              text,
              list,
              quotes {
                richText,
                author,
              }
            },
            block3 {
              title,
              header,
              cta,
              casestudies {
                id,
                title,
                extend {
                  blocks {
                    header, 
                    text,
                    list,
                  },
                  quote,
                  fromto_1_header,
                  fromto_1_desc,
                  fromto_1_content,
                  fromto_2_header,
                  fromto_2_desc,
                  fromto_2_content,
                  more,
                  more_link,
                }
              }
            },
            block4 {
              title,
              description,
              textBlock,
              contact,
              image {
                path,
                alt
              },
            },
          }
        }
      }
    }
  }
`
const Main = styled.div`
    opacity: 0;
    &.loaded{
      opacity: 1;
      transition: opacity 1s ease-out;
    }
`
const IndexPage = ({data}) => {
  const [navID, setNavID] = useState(-1);
  const [count, setCount] = useState(-1);
  const [offset, setOffset] = useState(-50);
  const [loaded, setLoaded] = useState(false);
  const content = data.site.siteMetadata.main.homepage;

  useEffect(()=>{
    window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoaded(true);
    }, 500)
  },[]);

  useEffect(()=>{
    setOffset(count === 1 ? -82 : -50);
  },[count]);

  useEffect(() => {
    setCount(count+1);
  }, [navID])

  const updateStatus = () => {
    setOffset(-82);
    setCount(0);
  }

  
  return (
  <Layout setNavID={(val)=>setNavID(val)} updateStatus={updateStatus}>
    <SEO title="Home" />
    <Main className={loaded && 'loaded'}>
        <BlockHero header={content.blockHero.header} navID={navID} offset={offset}/>
        <Block2 content={content.block2} navID={navID} offset={offset}/>
        <Block3 content={content.block3} navID={navID} offset={offset}/>
        <Block4 content={content.block4} navID={navID} offset={offset}/>
    </Main>
  </Layout>
  )
}


export default IndexPage